import React, {useState, useEffect, useRef} from "react";
import {
  StyleSheet,
  Image,
  Text,
  View,
  TextInput,
  TouchableWithoutFeedback, 
  Platform,
} from "react-native";
import {commonStyles, AppButton} from "../style/commonStyle";
import {palette} from "../style/palette";
import {userApi} from "../api/userApi";
import history from '../history/history';
import {userSession} from "../api/userSession";
import {Spinner} from "../components/spinner";
import {log} from "../logger/logger";
import {calculateInitialNavigationRoute} from "./appStartupHandler";
import PhoneInput from "react-native-phone-number-input";
import {PhoneNumberUtil} from "google-libphonenumber";
import {displayError} from "../components/errorPanel";
import {getGeoIpCountry} from "../geo/geo";

const phoneUtil = PhoneNumberUtil.getInstance();

const styles = StyleSheet.create({
  midSection: {
    width: "100%",
    maxWidth: 400,
    paddingHorizontal: 40,
    flex: 1,
    alignItems: "stretch",
  },
  cerahIcon: {
    width: 120,
    height: 120,
    resizeMode: "contain",
    paddingVertical: 120,
  },
  otpInfoContainer: {
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  otpInputContainer: {
    flexDirection: 'row',
    marginBottom: 20,
    justifyContent: "center",
  },
  otpInput: {
    borderWidth: 1,
    marginHorizontal: 5,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    borderRadius: 10,
    borderColor: palette.$grey,
    color: palette.$accent1Shade3
  },
  digitInput: {
    fontSize: 22,
    fontWeight: "700",
    textAlign: 'center',
    maxWidth: 30
  },
  resendText: {
    alignSelf: 'flex-end'
  },
  disabledLink: {
    color: "#aaa",
  }
})

const stylePhoneInput = {
  phoneNumberInputContainer: {
    overflow: "hidden",
    flexDirection: "row",
    alignItems: "center",
    borderColor: palette.$grey,
    borderWidth: 1.2,
    borderRadius: 30,
    marginTop: 10,
    marginBottom: 30,
    maxWidth: "100%",
  },
  flagButton: {
    maxWidth: 80,
    margin: 0,
    padding: 0
  },
  phoneInput: {
    alignSelf: "center",
    paddingVertical: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  textInput: {
    fontSize: 16,
    width: 170,
  },
  codeText: {
    fontSize: 16,
    marginRight: 0
  }
}

if (Platform.OS === "web") {
  stylePhoneInput.textInput.appearance = "textField"; //This is to remove the arrows in web
}

let geoFailed = true;

export function OtpLogin(props) {
  const [phone, setPhone] = useState(null);
  const [formattedPhone, setFormattedPhone] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [countryCode, setCountryCode] = useState(null);
  const [phoneCountryCode, setPhoneCountryCode] = useState(null);
  const phoneRef = useRef(undefined);
  let iconTapCount = 0;

  useEffect(() => {guessCountry()}, [])

  async function guessCountry() {
    const countryCode = await getGeoIpCountry();
    if (countryCode) {
      geoFailed = false;
    }
    setCountryCode(countryCode || "ID");
  }

  function handleIconTap() {
    iconTapCount++;
    if (iconTapCount === 10) {
      iconTapCount = 0;
      history().push("LoginEmail", {});
    }
  }

  return (
    <View style={commonStyles.page}>
      <TouchableWithoutFeedback onPress={handleIconTap}>
        <Image
          style={styles.cerahIcon}
          source={require('../../assets/iconWithText.png')}
        />
      </TouchableWithoutFeedback>
      <View style={styles.midSection}>
        <Text style={commonStyles.headingText}>
          Login using OTP
        </Text>

        {!otpSent && countryCode &&
          <>
            <Text style={commonStyles.infoText}>
              Get a verification code via WhatsApp:
            </Text>
            <PhoneInput
              containerStyle={stylePhoneInput.phoneNumberInputContainer}
              textContainerStyle={stylePhoneInput.phoneInput}
              flagButtonStyle={stylePhoneInput.flagButton}
              codeTextStyle={stylePhoneInput.codeText}
              textInputStyle={stylePhoneInput.textInput}
              defaultCode={countryCode}
              ref={phoneRef}
              value={phone}
              placeholder="  -  -  -  -  -  -  -  -  -  -"
              onChangeText={(phoneNumber) => {
                phoneChanged(phoneNumber);
              }}
              countryPickerProps={{
                preferredCountries: ["ID", "ES", "GB"],
              }}
            />
            {<AppButton onPress={requestOtp} title={requesting ? "" : "Request OTP"}>
              {requesting && <Spinner/>}
            </AppButton>}
          </>
        }
        {otpSent &&
          <OtpVerification
            phone={formattedPhone}
            onOtpResendRequested={() => setOtpSent(false)}
            countryCode={!geoFailed ? countryCode : phoneCountryCode}
          />
        }
      </View>
      <Image
        style={commonStyles.backgroundDoodle}
        source={require('../../assets/backgroundDoodle.png')}
      />
    </View>
  );

  function phoneChanged(newPhone) {
    setPhone(newPhone);
  }

  async function requestOtp() {
    if (requesting) return;

    if (!validatePhoneNumber(phone, phoneRef.current.getCountryCode())) {
      return;
    }
    setPhoneCountryCode(phoneRef.current.getCountryCode());

    const formattedNumber = phoneRef.current.getNumberAfterPossiblyEliminatingZero().formattedNumber;
    setFormattedPhone(formattedNumber);

    setRequesting(true);
    try {
      await userApi.requestOtp(formattedNumber);
      setOtpSent(true);
    } catch (e) {
      log.error("Failed to send OTP", JSON.stringify(e));
      displayError("Failed to send OTP, please check number and try again");
    }
    setRequesting(false);
  }

  function validatePhoneNumber(phone, countryCode) {
    if (phone !== null && phone.length > 0) {
      const pattern = new RegExp(/^[0-9]+$/);
      if (!pattern.test(phone)) {
        displayError("Please enter only numbers");
        return false;
      }
    }
    const number = phoneUtil.parse(phone, countryCode);
    if (!phoneUtil.isValidNumber(number)) {
      displayError("Please, enter a valid number")
      return false;
    }
    return true;
  }
}

function OtpVerification(props) {
  const {phone, countryCode} = props;
  const RESEND_OTP_TIMEOUT = 30;
  const [otpArray, setOtpArray] = useState(Array(6).fill(""));
  const [verificationError, setVerificationError] = useState(null);
  const [secs, setSecs] = useState(RESEND_OTP_TIMEOUT);
  const [submitting, setSubmitting] = useState(false);

  const digitInputElRefs = Array(6).fill(null).map(dummy => useRef(null));

  useEffect(() => {
    restartTimeoutCounter();
  }, [])

  function restartTimeoutCounter() {
    setSecs(RESEND_OTP_TIMEOUT);
    function updateSecs() {
      if (secs <= 0) return;
      setSecs(secs => secs - 1);
      setTimeout(updateSecs, 1000);
    }
    setTimeout(updateSecs, 1000);
  }

  return (
    <View>
      {verificationError &&
        <Text style={commonStyles.errorText}>
          {verificationError}
        </Text>
      }
      <Text style={commonStyles.infoText}>
        Enter the code sent to {phone}
      </Text>
      <View style={styles.otpInputContainer}>
        {
          digitInputElRefs.map((textInputRef, index) => (
            <View style={styles.otpInput} key={index}>
              <TextInput
                style={styles.digitInput}
                keyboardType={'numeric'}
                maxLength={1}
                onChangeText={onOtpChange(index)}
                onKeyPress={onKeyPress(index)}
                key={index}
                value={otpArray[index]}
                autoFocus={index === 0 ? true : undefined}
                ref={digitInputElRefs[index]}
                textAlign="center"
              />
            </View>
          ))
        }
      </View>
      <AppButton onPress={submitOtp} title={!submitting && "Submit OTP"}>
        {submitting && <Spinner/>}
      </AppButton>
      <Text style={[commonStyles.infoText, styles.resendText, commonStyles.linkText, secs > 0 ? styles.disabledLink : null]}
            onPress={resendOtp}>
        Get new OTP{secs > 0 ? ` in ${secs}s` : ""}
      </Text>
    </View>
  )

  function onOtpChange(index) {
    return value => {
      if (value !== "" && !(/[0-9]/.test(value))) {
        // do nothing when a non digit is pressed
        return;
      }

      setOtpArray(otpArray => {
        const otpArrayCopy = [...otpArray];
        otpArrayCopy[index] = value;
        return otpArrayCopy;
      });

      if (value === "" && index > 0) {
        digitInputElRefs[index - 1].current.focus();
      }

      // auto focus to next InputText if value is not blank
      if (value !== '') {
        if (index < 5) {
          digitInputElRefs[index + 1].current.focus();
        }
      }
    }
  }

  function onKeyPress(index) {
    return ({nativeEvent: {key}}) => {
      if (key === "Backspace" && index > 0) {
        digitInputElRefs[index - 1].current.focus();
      }
    }
  }

  async function submitOtp() {
    if (submitting) return;
    setSubmitting(true)
    try {
      const code = otpArray.join("");
      const token = await userApi.verifyOtp(code, phone, countryCode);
      await userSession.userChanged(token);
      const {route, params} = await calculateInitialNavigationRoute();
      history().reset(route, params);
    } catch (e) {
      log.error("Failed on OTP verification", e);
      setVerificationError("Invalid OTP. Please try again");
    }
    setSubmitting(false);
  }

  async function resendOtp() {
    if (secs <= 0) {
      setOtpArray(["", "", "", "", "", ""]);
      digitInputElRefs[0].current.focus();
      props.onOtpResendRequested();
      restartTimeoutCounter();
    }
  }
}
